const post = [
  {
    id: "1",
    title: "İnsanı İnsan Yapan Devrim, Bilişsel Devrim",
    link: "/post/bilisseldevrim",
  },
  {
    id: "2",
    title: "Sözde Rastgele Sayılar",
    link: "/post/sozderastgelesayilar",
  },
];

export default post;
